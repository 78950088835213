import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { Prompt } from '../../../ui/Prompt/PromptComponent';
import { $Paperwork } from '../../state/PaperworkState';
import { SelectField } from '../atoms/SelectField';
const onChange = (setNextActionLabel, setNextActionValue, nextActions) => (e) => {
    if (typeof e.target.value !== 'string') {
        return;
    }
    const nextAction = nextActions.find(({ label }) => label === e.target.value);
    if (!nextAction) {
        return;
    }
    setNextActionValue(nextAction.value);
    setNextActionLabel(e.target.value);
};
export const PaperworkProcessModal = ({ isLoading, isOpen, onClose, nextActions }) => {
    const [nextActionLabel, setNextActionLabel] = useState('');
    const [nextActionValue, setNextActionValue] = useState('');
    const dispatch = useAppDispatch();
    return (_jsx(Prompt, { isOpen: isOpen, isLoading: isLoading, title: "Modifica stato", onCancelAction: onClose, onConfirmText: "Conferma", onConfirmAction: () => {
            dispatch($Paperwork.processPaperwork({ nextActionValue: nextActionValue, onSuccess: onClose }));
        }, children: _jsx(SelectField, { dense: true, label: "Seleziona uno stato", name: "nextActionValue", onChange: onChange(setNextActionLabel, setNextActionValue, nextActions), onBlur: () => undefined, options: nextActions, value: nextActionLabel, required: true }) }));
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ActiveInvoiceTabs } from '../activeInvoicing/ui/ActiveInvoiceTabs';
import { isUserAuthorized } from '../authorization/useAuthorization';
import { useAppSelector } from '../hooks/useAppSelector';
import { PaperworkPage } from '../paperwork/ui/pages/PaperworkPage';
import { PassiveInvoiceTabs } from '../passiveInvoicing/ui/PassiveInvoiceTabs';
import ProcessingQuotesRoute from '../quotingTool/ui/Route';
import { ScrollToTop } from '../quotingTool/ui/ScrollToTop';
import { history } from '../store/Store';
import { PERMISSION, SecurityState } from '../store/security/SecurityState';
import PrivateRoute from './PrivateRoute';
const COMMISSION_PLAN = '/piani-provvigionali';
const PASSIVE_INVOICE = '/fatture';
const ACTIVE_INVOICE = '/fatture/clienti';
const MORTGAGE = '/mutuo';
const LEAD = '/anagrafica';
const MPN = '/mpn';
const RESULTS = '/risultati';
const MPN_RESULTS = '/risultati-mpn';
const QUOTE = '/preventivo';
const ENASARCO = '/enasarco';
const QUOTE_RESULTS = `${QUOTE}${RESULTS}`;
const QUOTE_MPN_RESULTS = `${QUOTE}${MPN_RESULTS}`;
const QUOTE_LEAD = `${QUOTE}${LEAD}`;
const QUOTE_MORTGAGE = `${QUOTE}${MORTGAGE}`;
const QUOTE_MPN = `${QUOTE}${MPN}`;
const MORTAGE_RESULTS = `${MORTGAGE}${RESULTS}`;
const ACTIVE_RECORDS = '/pratiche/in-lavorazione';
const CLOSED_RECORDS = '/pratiche/concluse';
const RECORD_ID = '/pratica/:id';
const COASSIGNEE = '/coassegnatario';
const PROCESSING_QUOTES = '/preventivi';
const LOGIN = '/login';
const HOME = '/';
const Root = () => _jsx(Redirect, { to: ACTIVE_RECORDS });
const ActiveRecords = lazy(() => import('../ui/ActiveRecords'));
const ClosedRecords = lazy(() => import('../ui/ClosedRecords'));
const RecordDetail = lazy(() => import('../ui/RecordDetail/RecordDetailComponent'));
const Layout = lazy(() => import('../quotingTool/ui/Layout'));
const EnasarcoLayout = lazy(() => import('../enasarco/ui/EnasarcoLayout'));
const CoassigneePaperworkLayout = lazy(() => import('../coassignee/ui/PaperworkLayout'));
const CommissionPlanLayout = lazy(() => import('../commissionPlan/ui/Layout'));
export const AppRoutes = () => {
    const userPermissionList = useAppSelector(SecurityState.selector.permissionList);
    return (_jsx(Router, { history: history, children: _jsx(Suspense, { fallback: _jsx(_Fragment, {}), children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: LOGIN, component: Root }), _jsx(PrivateRoute, { isAuthorized: true, exact: true, path: HOME, component: Root }), _jsx(PrivateRoute, { isAuthorized: true, path: ENASARCO, component: EnasarcoLayout }), _jsx(PrivateRoute, { exact: true, isAuthorized: isUserAuthorized(userPermissionList)(PERMISSION.VIEW_PASSIVE_INVOICE_ROUTE), path: PASSIVE_INVOICE, component: PassiveInvoiceTabs }), _jsx(PrivateRoute, { exact: true, isAuthorized: isUserAuthorized(userPermissionList)(PERMISSION.VIEW_ACTIVE_INVOICE_ROUTE), path: ACTIVE_INVOICE, component: ActiveInvoiceTabs }), _jsx(PrivateRoute, { isAuthorized: true, path: COMMISSION_PLAN, component: CommissionPlanLayout }), _jsx(PrivateRoute, { isAuthorized: true, path: ACTIVE_RECORDS, component: ActiveRecords }), _jsx(PrivateRoute, { isAuthorized: true, path: CLOSED_RECORDS, component: ClosedRecords }), _jsx(PrivateRoute, { isAuthorized: true, path: RECORD_ID, component: RecordDetail }), _jsx(PrivateRoute, { isAuthorized: !/mep\.facile\.it/.test(window.location.href), exact: true, path: "/pratiche/:paperworkId", component: () => _jsx(PaperworkPage, { activeTab: 0 }) }), _jsx(PrivateRoute, { isAuthorized: !/mep\.facile\.it/.test(window.location.href), exact: true, path: "/pratiche/:paperworkId/documentazione", component: () => _jsx(PaperworkPage, { activeTab: 1 }) }), _jsx(PrivateRoute, { isAuthorized: true, path: QUOTE, component: Layout }), _jsx(PrivateRoute, { isAuthorized: true, path: PROCESSING_QUOTES, component: ProcessingQuotesRoute }), _jsx(PrivateRoute, { isAuthorized: isUserAuthorized(userPermissionList)(PERMISSION.VIEW_SIDEBAR_BACK_OFFICE), path: COASSIGNEE, component: CoassigneePaperworkLayout }), _jsx(ScrollToTop, { children: _jsx(PrivateRoute, { isAuthorized: true, exact: true, path: "/preventivo/mutuo/mpn", component: Layout }) })] }) }) }));
};
export const $routes = {
    HOME,
    ACTIVE_RECORDS,
    CLOSED_RECORDS,
    QUOTE_MORTGAGE,
    MPN,
    RESULTS,
    MORTAGE_RESULTS,
    LEAD,
    QUOTE_LEAD,
    MORTGAGE,
    QUOTE,
    QUOTE_RESULTS,
    QUOTE_MPN,
    QUOTE_MPN_RESULTS,
    MPN_RESULTS,
    ENASARCO,
    PASSIVE_INVOICE,
    ACTIVE_INVOICE,
    COMMISSION_PLAN,
    COASSIGNEE,
    PROCESSING_QUOTES,
};

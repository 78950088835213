import { array, either, eq, option, ord, readonlyArray, string, taskEither } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { AxiosClient } from '../../../common/clients/AxiosClient';
import { Session } from '../../../session/state/Session';
import { commissionItemModelMapper } from './mappers/commissionItemModelMapper';
import { localityModelMapper } from './mappers/localityModelMapper';
import { paperworkModelMapper } from './mappers/paperworkModelMapper';
import { productModelMapper } from './mappers/productModelMapper';
import { storeModelMapper } from './mappers/storeModelMapper';
import { channel } from './models/Channel';
import { commissionItem } from './models/CommissionItem';
import { institute } from './models/Institute';
import { locality } from './models/Locality';
import { paperwork } from './models/Paperwork';
import { product } from './models/Product';
import { store } from './models/Store';
export class BazaarClient extends AxiosClient {
    isHttpError(e) {
        return typeof e === 'object' && e !== null && 'response' in e;
    }
    getCityAndProvinceShortName(fieldValue) {
        return pipe(fieldValue.split(/^(.*)[\s]\((.+)\)$/), // example: xxx (xxx)
        option.fromNullable, option.bindTo('placeOfResidenceSplit'), option.bind('city', ({ placeOfResidenceSplit }) => pipe(placeOfResidenceSplit, readonlyArray.lookup(1))), option.bind('provinceShortName', ({ placeOfResidenceSplit }) => pipe(placeOfResidenceSplit, readonlyArray.lookup(2))), option.map(({ city, provinceShortName }) => ({ city, provinceShortName })));
    }
    updatePaperwork({ formData, paperworkId }) {
        const token = Session.getToken();
        const payload = {
            nome: formData.participant.firstName,
            cognome: formData.participant.lastName,
            nome_e_cognome: `${formData.participant.firstName} ${formData.participant.lastName}`,
            indirizzo_di_residenza: formData.participant.residence.address,
            localita_di_residenza: formData.participant.residence.locationInput,
            comune_di_residenza: pipe(this.getCityAndProvinceShortName(formData.participant.residence.locationInput), option.map(({ city }) => city), option.getOrElse(constant(''))),
            provincia_di_residenza: pipe(this.getCityAndProvinceShortName(formData.participant.residence.locationInput), option.map(({ provinceShortName }) => provinceShortName), option.getOrElse(constant(''))),
            cap_di_residenza: formData.participant.residence.postalCode,
            comune_di_nascita: formData.participant.cityOfBirth,
            provincia_di_nascita: formData.participant.provinceOfBirth,
            data_di_nascita: formData.participant.birthday,
            sesso: formData.participant.gender,
            codice_fiscale: formData.participant.fiscalCode,
            tipo_di_documento: formData.participant.identityDocument.type,
            cittadinanza: formData.participant.citizenship,
            n_di_documento: formData.participant.identityDocument.code,
            rilasciato_da: formData.participant.identityDocument.issuedBy,
            luogo_di_rilascio: formData.participant.identityDocument.placeOfIssue,
            data_di_rilascio: formData.participant.identityDocument.issueDate,
            data_di_scadenza: formData.participant.identityDocument.expiryDate,
            telefono_cellulare: formData.participant.contacts.mobilePhone,
            telefono_fisso: formData.participant.contacts.landline,
            email: formData.participant.contacts.email,
            stato_civile: formData.participant.maritalStatus,
            professione: formData.participant.job,
            tipo_di_azienda: formData.participant.companyType,
            partita_iva_datore_di_lavoro: formData.participant.employerVatNumber,
            reddito_mensile_netto: formData.participant.netIncome,
            numero_di_mensilita: formData.participant.monthlyInstallment,
            anzianita_lavorativa: formData.participant.lengthOfService,
            componenti_nucleo_familiare: formData.participant.household,
            consenso_invio_comunicazioni_promozionali_e_marketing: formData.participant.marketing,
            consenso_cessione_dati_a_terzi: formData.participant.thirdParties,
            cessione_del_quinto_finalita: formData.cqs.purpose,
            cessione_del_quinto_tipo_di_operazione: formData.cqs,
            cessione_del_quinto_tipo_di_dipendente: formData.cqs.employeeType,
            cessione_del_quinto_finanziaria: formData.cqs.institute,
            cessione_del_quinto_prodotto: formData.cqs.product,
            cessione_del_quinto_importo_richiesto: formData.cqs.requestedAmount,
            cessione_del_quinto_taeg: formData.cqs.tan,
            cessione_del_quinto_punti_applicati: formData.cqs.points,
            cessione_del_quinto_durata_mesi: formData.cqs.duration,
            cessione_del_quinto_rata: formData.cqs.installment,
            cessione_del_quinto_montante: formData.cqs.interest,
            cessione_del_quinto_montante_residuo: formData.cqs.interestLeft,
            cessione_del_quinto_delta_montante: formData.cqs.interestDifference,
        };
        return pipe(taskEither.tryCatch(() => this.http.put(`/api/v2/records/${paperworkId}`, // to be defined
        { data: Object.assign({}, payload) }, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getPaperwork({ paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${paperworkId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getChannels() {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/channels`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(channel).decode));
    }
    getProducts(getProductsDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/products`, {
            params: {
                rubinetto: getProductsDto.channel,
                institute: getProductsDto.institute,
                productTypology: getProductsDto.productTypology,
                recordId: getProductsDto.paperworkId,
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(u => pipe(t.array(product).decode(u), either.map(array.filter(({ name }) => name !== 'none')), either.map(array.uniq(pipe(string.Eq, eq.contramap((duplicatedProduct) => duplicatedProduct.name)))), either.map(array.sort(pipe(string.Ord, ord.contramap((productToSort) => productToSort.name)))))), taskEither.map(array.map(productModelMapper)));
    }
    getInstitutes(getInstitutesDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/institutes`, {
            params: {
                productTypology: getInstitutesDto.productTypology,
                recordId: getInstitutesDto.paperworkId,
                rubinetto: getInstitutesDto.channel,
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(institute).decode));
    }
    getStores(storeName) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/stores`, {
            headers: this.addBearerAuthorization(token),
            params: {
                name: storeName,
                sort: 'name',
            },
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(store).decode), taskEither.map(array.map(storeModelMapper)));
    }
    getLocalities(getLocalitiesDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/localities`, {
            params: {
                search: getLocalitiesDto.search,
                'filter[type]': 'city',
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(locality).decode), taskEither.map(array.map(localityModelMapper)));
    }
    getCommissions(getCommissionsDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${getCommissionsDto.paperworkId}/commissions`, {
            headers: this.addBearerAuthorization(token),
        }), e => {
            if (this.isHttpError(e)) {
                return new Error('Non è stato possibile calcolare le provvigioni, controlla i dati inseriti.');
            }
            return new Error('Si è verificato un errore');
        }), taskEither.chain(response => pipe(response.data, t.array(t.array(commissionItem)).decode, either.map(array.map(array.map(commissionItemModelMapper))), taskEither.fromEither, taskEither.mapLeft(() => {
            return new Error('Si è verificato un errore');
        }))));
    }
    processPaperwork(transitDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`/api/v1/paperwork/${transitDto.paperworkId}/transit`, { context: null, action: transitDto.action }, {
            headers: this.addBearerAuthorization(token),
        }), identity));
    }
}

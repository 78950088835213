import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Prompt } from '../../../ui/Prompt/PromptComponent';
import { $PaperworkSlice } from '../../state/PaperworkState';
import { CommissionError } from '../atoms/CommissionError';
import { CommissionsTotal } from '../atoms/CommissionsTotal';
import { Visibility } from '../atoms/Visibility';
import { CommissionsTable } from './CommissionsTable';
const useStyles = makeStyles()(() => ({
    divider: { marginBottom: '8px', marginTop: '16px' },
    paper: { padding: '16px' },
}));
const toggleVisibility = (stateSetter) => () => stateSetter(previousVisibility => !previousVisibility);
export const CommissionCard = () => {
    const { classes } = useStyles();
    const [showTotal, setShowTotal] = useState(false);
    const [showCommissionTable, setShowCommissionTable] = useState(false);
    const isLoading = useAppSelector($PaperworkSlice.selector.loading).commissions;
    const commissions = useAppSelector($PaperworkSlice.selector.commissions);
    return (_jsxs(_Fragment, { children: [showCommissionTable && (_jsx(Prompt, { onCloseAction: toggleVisibility(setShowCommissionTable), disableContentPadding: true, title: "Provvigioni", isOpen: showCommissionTable, maxWidth: "sm", denseTitle: true, children: _jsx(CommissionsTable, {}) })), _jsxs(Paper, { elevation: 0, variant: "outlined", className: classes.paper, children: [_jsxs(Grid, { container: true, alignItems: "center", flexWrap: "nowrap", children: [commissions.error === null && (_jsx(Grid, { item: true, children: _jsx(Visibility, { isVisible: showTotal, stateSetter: setShowTotal }) })), _jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h3", children: "Provvigioni" }) })] }), _jsx(Divider, { className: classes.divider }), _jsxs(Grid, { container: true, flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", children: [commissions.error === null && (_jsx(Grid, { item: true, children: _jsx(CommissionsTotal, { showTotal: showTotal, total: commissions.total, isLoading: isLoading }) })), commissions.error && _jsx(CommissionError, { message: commissions.error }), commissions.error === null && (_jsx(Grid, { item: true, children: _jsx(Button, { size: "small", disabled: isLoading, variant: "contained", onClick: toggleVisibility(setShowCommissionTable), children: "Dettagli" }) }))] })] })] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: {
        paddingRight: '10px',
        backgroundColor: 'white',
    },
}));
export const SelectField = ({ name, value, options, onChange, label, required = false, disabled = false, error = '', isTouched = false, helperText, startAdornmentLabel, dense = false, onBlur, }) => {
    const { classes } = useStyles();
    return (_jsxs(FormControl, { sx: dense ? { marginBottom: 0 } : {}, margin: "dense", disabled: disabled, fullWidth: true, children: [_jsx(InputLabel, { required: required, className: classes.label, style: { paddingRight: '6px' }, children: label }), _jsx(Select, { disabled: options.length === 0, error: isTouched && error !== '', name: name, value: value, label: label, onBlur: onBlur, onChange: onChange, startAdornment: startAdornmentLabel ? (_jsx(InputAdornment, { position: "start", children: startAdornmentLabel })) : undefined, children: options.map((option, index) => {
                    if (typeof option === 'object' && 'label' in option && 'value' in option) {
                        return (_jsx(MenuItem, { value: option.label, children: option.label }, index));
                    }
                    else {
                        return (_jsx(MenuItem, { value: option, children: option }, index));
                    }
                }) }), helperText && error === '' && _jsx(FormHelperText, { children: helperText }), error !== '' && isTouched && _jsx(FormHelperText, { error: true, children: error })] }));
};

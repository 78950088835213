import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import RequestPageIcon from '@mui/icons-material/RequestPageOutlined';
import { Divider, ListItemButton, ListSubheader, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import { useState } from 'react';
import { useAuthorization } from '../../authorization/useAuthorization';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $routes } from '../../routes/routes';
import { SecurityActions } from '../../store/security/SecurityActions';
import { PERMISSION } from '../../store/security/SecurityState';
import { toggle } from '../../utils/toggle';
import { useStyles } from './Sidebar.styles';
const toggleSidebar = (setOpen) => () => setOpen(previousState => !previousState);
const onClick = (path, key, setOpen, setSelectedItems, selectedItems, dispatch) => () => {
    dispatch(SecurityActions.RefreshSource({ pathname: path }));
    setSelectedItems(toggle.one(selectedItems, key));
    toggleSidebar(setOpen)();
};
export const Sidebar = ({ setOpen, open }) => {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const pathname = useAppSelector((state) => (state.router.location ? state.router.location.pathname : ''));
    const canViewMyInvoicesText = useAuthorization([PERMISSION.VIEW_MY_INVOICES_TEXT]);
    const canViewMyPersonalArea = useAuthorization([PERMISSION.VIEW_SIDEBAR_PERSONAL_AREA]);
    const canViewBackOffice = useAuthorization([PERMISSION.VIEW_SIDEBAR_BACK_OFFICE]);
    const canEditEnasarco = useAuthorization([PERMISSION.EDIT_ENASARCO]);
    const uploadCommissionPlan = useAuthorization([PERMISSION.UPLOAD_COMMISSION_PLAN]);
    const [selectedItems, setSelectedItems] = useState({
        activeRecords: pathname === $routes.ACTIVE_RECORDS,
        closedRecords: pathname === $routes.CLOSED_RECORDS,
        passiveInvoicing: pathname === $routes.PASSIVE_INVOICE,
        activeInvoicing: pathname === $routes.ACTIVE_INVOICE,
        quote: pathname === $routes.QUOTE_LEAD,
        enasarco: pathname === $routes.ENASARCO,
        coassignee: pathname === $routes.COASSIGNEE,
        processingQuotes: pathname === $routes.PROCESSING_QUOTES,
        commissionPlan: pathname === $routes.COMMISSION_PLAN,
    });
    return (_jsx(Drawer, { open: open, onClose: toggleSidebar(setOpen), classes: { paper: clsx(classes.paperRoot, classes.sidebarBorderRadius) }, children: _jsxs(List, { component: "nav", className: classes.mt2, children: [_jsx(ListSubheader, { className: classes.mtb5, color: "primary", sx: { bgcolor: 'transparent' }, children: _jsx(Typography, { variant: "h6", children: "Artoo" }) }), _jsx(ListSubheader, { className: classes.mtb5, color: "primary", sx: { bgcolor: 'transparent' }, children: _jsx(Typography, { variant: "h6", children: "Preventivi" }) }), _jsxs(ListItemButton, { selected: selectedItems.processingQuotes, onClick: onClick($routes.PROCESSING_QUOTES, 'processingQuotes', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: clsx(classes.ml4), primaryTypographyProps: {
                                variant: 'h6',
                            }, primary: "Preventivi in lavorazione" })] }), _jsxs(ListItemButton, { selected: selectedItems.quote, onClick: () => {
                        onClick($routes.QUOTE, 'quote', setOpen, setSelectedItems, selectedItems, dispatch)();
                    }, className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(HomeWorkOutlinedIcon, {}), _jsx(ListItemText, { className: clsx(classes.ml4), primaryTypographyProps: {
                                variant: 'h6',
                            }, primary: "Nuovo preventivo" })] }), _jsx(Divider, { className: clsx(classes.mr4, classes.ml4) }), _jsx(ListSubheader, { className: classes.mtb5, color: "primary", sx: { bgcolor: 'transparent' }, children: _jsx(Typography, { variant: "h6", children: "Pratiche" }) }), _jsxs(ListItemButton, { selected: selectedItems.activeRecords, onClick: onClick($routes.ACTIVE_RECORDS, 'activeRecords', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(InsertDriveFileOutlinedIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                variant: 'h6',
                            }, primary: "In lavorazione" })] }), _jsxs(ListItemButton, { selected: selectedItems.closedRecords, onClick: onClick($routes.CLOSED_RECORDS, 'closedRecords', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(DoneOutlinedIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                variant: 'h6',
                            }, primary: "Concluse" })] }), _jsx(Divider, { className: clsx(classes.mr4, classes.ml4) }), canViewMyPersonalArea && (_jsx(ListSubheader, { className: classes.mtb5, color: "primary", sx: { bgcolor: 'transparent' }, children: _jsx(Typography, { variant: "h6", children: "Area personale" }) })), canViewMyInvoicesText && (_jsxs(_Fragment, { children: [_jsxs(ListItemButton, { selected: selectedItems.passiveInvoicing, onClick: onClick($routes.PASSIVE_INVOICE, 'passiveInvoicing', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                        variant: 'h6',
                                    }, primary: "Le mie fatture" })] }), _jsxs(ListItemButton, { selected: selectedItems.activeInvoicing, onClick: onClick($routes.ACTIVE_INVOICE, 'activeInvoicing', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                        variant: 'h6',
                                    }, primary: "Fatture clienti" })] })] })), canViewBackOffice && (_jsx(ListSubheader, { className: classes.mtb5, color: "primary", sx: { bgcolor: 'transparent' }, children: _jsx(Typography, { variant: "h6", children: "Back office" }) })), uploadCommissionPlan && (_jsxs(ListItemButton, { selected: selectedItems.commissionPlan, onClick: onClick($routes.COMMISSION_PLAN, 'commissionPlan', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                variant: 'h6',
                            }, primary: "Gestione piani provvigionali" })] })), canEditEnasarco && (_jsxs(ListItemButton, { selected: selectedItems.enasarco, onClick: onClick($routes.ENASARCO, 'enasarco', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                variant: 'h6',
                            }, primary: "Gestione massimali Enasarco" })] })), canViewBackOffice && (_jsxs(_Fragment, { children: [_jsxs(ListItemButton, { selected: selectedItems.passiveInvoicing, onClick: onClick($routes.PASSIVE_INVOICE, 'passiveInvoicing', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                        variant: 'h6',
                                    }, primary: "Fatturazione passiva" })] }), _jsxs(ListItemButton, { selected: selectedItems.activeInvoicing, onClick: onClick($routes.ACTIVE_INVOICE, 'activeInvoicing', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(RequestPageIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                        variant: 'h6',
                                    }, primary: "Fatturazione attiva" })] }), _jsxs(ListItemButton, { selected: selectedItems.coassignee, onClick: onClick($routes.COASSIGNEE, 'coassignee', setOpen, setSelectedItems, selectedItems, dispatch), className: clsx(classes.cursorPointer, classes.listItem), children: [_jsx(InsertDriveFileOutlinedIcon, {}), _jsx(ListItemText, { className: classes.ml4, primaryTypographyProps: {
                                        variant: 'h6',
                                    }, primary: "Gestione coassegnatario" })] })] }))] }) }));
};

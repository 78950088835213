import { documentationModelMapper } from './documentationModelMapper';
import { productTypologyModelMapper } from './productTypologyModelMapper';
export const paperworkModelMapper = (paperwork) => {
    var _a;
    return ({
        data: paperwork.data,
        details: {
            id: paperwork.id,
            productTypology: productTypologyModelMapper(paperwork.productType),
            assignedTo: paperwork.assignedTo,
            createdAt: paperwork.createdAt,
            createdBy: paperwork.createdBy,
            artooId: paperwork.artooId,
            transitionsHistory: paperwork.transitionsHistory.map(transition => ({
                status: typeof transition.status === 'string' ? transition.status : transition.status.label,
                triggeredAt: transition.triggeredAt,
                triggeredBy: transition.triggeredBy,
            })),
            nextActions: paperwork.nextActions.map(nextAction => ({
                value: nextAction.code,
                label: nextAction.label,
            })),
            documentation: ((_a = paperwork.documentation) === null || _a === void 0 ? void 0 : _a.map(documentationModelMapper)) || [],
        },
    });
};

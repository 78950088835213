import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkSlice } from '../../state/PaperworkState';
import { Loader } from '../atoms/Loader';
import { DocumentationCard } from '../molecules/DocumentationCard';
export const DocumentationPage = () => {
    const isPageLoading = useAppSelector($PaperworkSlice.selector.isPageLoading);
    const documentation = useAppSelector($PaperworkSlice.selector.documentation);
    return (_jsxs(_Fragment, { children: [isPageLoading && _jsx(Loader, {}), documentation && _jsx(DocumentationCard, { title: "Documentazione", documentation: documentation })] }));
};

import { createSlice } from '@reduxjs/toolkit';
import { identity } from 'fp-ts/function';
import { channelStateMapper } from './mappers/channelStateModelMapper';
import { commissionsTableModelMapper } from './mappers/commissionsTableModelMapper';
import { commissionsTotalStateMapper } from './mappers/commissionsTotalStateMapper';
import { detailStateModelMapper } from './mappers/detailStateModelMapper';
import { documentationStateMapper } from './mappers/documentationStateModelMapper';
import { formStateModelMapper } from './mappers/formStateModelMapper';
import { paperworkDetailStateMapper } from './mappers/paperworkDetailMapper';
const initialState = {
    initialValues: null,
    loading: {
        store: false,
        channel: false,
        institute: false,
        product: false,
        participantPlaceOfResidence: false,
        paperwork: false,
        commissions: false,
        processPaperwork: false,
    },
    fieldErrors: {
        store: undefined,
        channel: undefined,
        institute: undefined,
        product: undefined,
        participantPlaceOfResidence: undefined,
    },
    stores: [],
    channels: [],
    institutes: [],
    products: [],
    participantPlaceOfResidence: [],
    commissions: { table: { body: [], headers: [] }, total: null, error: null },
    paperworkDetail: null,
    detail: null,
    documentation: null,
};
const GENERIC_ERROR = 'Si è verificato un errore';
export const paperworkSlice = createSlice({
    name: 'PaperworkSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        fetchChannels: identity,
        fetchLocation: (state, _) => state,
        fetchStores: (state, _) => state,
        fetchInstitutes: (state, _) => state,
        fetchProducts: (state, _) => state,
        updatePaperwork: (state, _) => state,
        fetchPaperwork: identity,
        fillForm: (state, _) => state,
        fetchCommissions: identity,
        processPaperwork: (state, _) => state,
        ProcessPaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: true }) })),
        PaperworkProcessed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }) })),
        PaperworkNotProcessed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }) })),
        CommissionsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { commissions: true }) })),
        CommissionsFetched: (state, event) => (Object.assign(Object.assign({}, state), { commissions: Object.assign(Object.assign({}, state.commissions), { table: commissionsTableModelMapper(event.payload.commissions), total: commissionsTotalStateMapper(event.payload.commissions) }), loading: Object.assign(Object.assign({}, state.loading), { commissions: false }) })),
        CommissionsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { commissions: false }), commissions: { table: { body: [], headers: [] }, total: null, error: event.payload.error.message } })),
        FormFillRequested: identity,
        FormNotFilled: identity,
        FormFilled: (state, event) => (Object.assign(Object.assign({}, state), { initialValues: formStateModelMapper(event.payload.paperwork.data) })),
        PaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(event.payload.paperwork, event.payload.permissions), documentation: documentationStateMapper(event.payload.paperwork.details.documentation) })),
        PaperworkNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        PaperworkUpdateRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        PaperworkNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        LocationRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { participantPlaceOfResidence: undefined }), loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: true }) })),
        LocationFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: false }), participantPlaceOfResidence: event.payload.participantPlaceOfResidence })),
        LocationNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { participantPlaceOfResidence: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: false }) })),
        ProductsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { product: undefined }), loading: Object.assign(Object.assign({}, state.loading), { product: true }) })),
        ProductsFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { product: false }), products: event.payload.products })),
        ProductsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { product: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { product: false }), products: [] })),
        InstitutesRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { institute: undefined }), loading: Object.assign(Object.assign({}, state.loading), { institute: true }) })),
        InstitutesFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { institute: false }), institutes: event.payload.institutes })),
        InstitutesNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { institute: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { institute: false }), institutes: [] })),
        ChannelsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { channel: undefined }), loading: Object.assign(Object.assign({}, state.loading), { channel: true }) })),
        ChannelsFetched: (state, event) => (Object.assign(Object.assign({}, state), { channels: event.payload.channels.map(channelStateMapper), loading: Object.assign(Object.assign({}, state.loading), { channel: false }) })),
        ChannelsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { channel: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { channel: false }), channels: [] })),
        StoresRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { store: true }), fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { store: undefined }) })),
        StoresFetched: (state, event) => (Object.assign(Object.assign({}, state), { stores: event.payload.stores, loading: Object.assign(Object.assign({}, state.loading), { store: false }) })),
        StoresNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { store: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { store: false }) })),
        goBack: identity,
        Started: identity,
        Stopped: identity,
    },
});
export const $Paperwork = paperworkSlice.actions;
export const $PaperworkSlice = {
    selector: {
        detail: (state) => state.paperwork.detail,
        paperwork: (state) => state.paperwork.paperworkDetail,
        initialValues: (state) => state.paperwork.initialValues,
        loading: (state) => state.paperwork.loading,
        stores: (state) => state.paperwork.stores,
        channels: (state) => state.paperwork.channels,
        institutes: (state) => state.paperwork.institutes,
        products: (state) => state.paperwork.products,
        participantPlaceOfResidence: (state) => state.paperwork.participantPlaceOfResidence,
        commissions: (state) => state.paperwork.commissions,
        isPageLoading: (state) => state.paperwork.loading.processPaperwork ||
            state.paperwork.loading.commissions ||
            state.paperwork.loading.paperwork,
        documentation: (state) => state.paperwork.documentation,
        fieldErrors: (state) => state.paperwork.fieldErrors,
    },
};
export const PaperworkState = paperworkSlice.reducer;

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    title: {
        color: '#6F797A',
    },
}));
export const DocumentationItem = ({ isRequired, title }) => {
    const { classes } = useStyles();
    return (_jsx(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: _jsx(Grid, { item: true, children: _jsxs(Typography, { fontWeight: 500, className: classes.title, children: [title, " ", isRequired && '*'] }) }) }));
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $Paperwork } from '../../state/PaperworkState';
import { CurrencyField } from '../atoms/CurrencyField';
import { InputField } from '../atoms/InputField';
import { SelectField } from '../atoms/SelectField';
import { Title } from '../atoms/Title';
const INTEREST_DIFFERENCE = 'cqs.interestDifference';
const INTEREST = 'cqs.interest';
const INTEREST_LEFT = 'cqs.interestLeft';
const INSTALLMENT = 'cqs.installment';
const REQUESTED_AMOUNT = 'cqs.requestedAmount';
export const CqsFormProductSection = ({ handleChange, setFieldValue, values, errors, httpErrors, touched, products, institutes, isInstitutesLoading, isProductsLoading, paperworkId, handleBlur, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    const dispatch = useAppDispatch();
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Prodotto: CQS" }), _jsx(SelectField, { label: "Finalit\u00E0", name: "cqs.purpose", onChange: handleChange, onBlur: handleBlur, options: ['Cessione del Quinto', 'Delega di Pagamento', 'TFS'], value: values.cqs.purpose, required: true, error: (_a = errors.cqs) === null || _a === void 0 ? void 0 : _a.purpose, isTouched: (_b = touched.cqs) === null || _b === void 0 ? void 0 : _b.purpose }), _jsx(SelectField, { label: "Tipo di operazione", name: "cqs.type", onChange: handleChange, onBlur: handleBlur, options: ['Nuovo', 'Rinnovo'], value: values.cqs.type, required: true, error: (_c = errors.cqs) === null || _c === void 0 ? void 0 : _c.type, isTouched: (_d = touched.cqs) === null || _d === void 0 ? void 0 : _d.type }), _jsx(SelectField, { label: "Tipo di dipendente", name: "cqs.employeeType", onChange: handleChange, onBlur: handleBlur, options: [
                    'Dipendente privato',
                    'Dipendente pubblico',
                    'Dipendente statale',
                    'Dipendente parapubblico',
                    'Pensionato',
                ], value: values.cqs.employeeType, error: (_e = errors.cqs) === null || _e === void 0 ? void 0 : _e.employeeType, isTouched: (_f = touched.cqs) === null || _f === void 0 ? void 0 : _f.employeeType }), _jsx(SelectField, { label: "Finanziaria", name: "cqs.institute", onChange: e => {
                    dispatch($Paperwork.fetchProducts({
                        channel: values.channel,
                        institute: e.target.value,
                        paperworkId: paperworkId,
                        productTypology: 'CQS',
                    }));
                    setFieldValue('cqs.product', '');
                    handleChange(e);
                }, onBlur: handleBlur, options: institutes.map(institute => institute.name), value: values.cqs.institute, disabled: isInstitutesLoading, required: true, error: httpErrors.institute || ((_g = errors.cqs) === null || _g === void 0 ? void 0 : _g.institute), isTouched: ((_h = touched.cqs) === null || _h === void 0 ? void 0 : _h.institute) || httpErrors.institute !== undefined }), (httpErrors.product || products.length > 0) && (_jsx(SelectField, { label: "Prodotto", name: "cqs.product", onChange: handleChange, onBlur: handleBlur, options: products.map(product => product.name), value: values.cqs.product, disabled: isProductsLoading, required: true, error: httpErrors.product || ((_j = errors.cqs) === null || _j === void 0 ? void 0 : _j.product), isTouched: ((_k = touched.cqs) === null || _k === void 0 ? void 0 : _k.product) || httpErrors.product !== undefined })), _jsx(CurrencyField, { fieldLabel: "Importo richiesto", name: REQUESTED_AMOUNT, value: values.cqs.requestedAmount, onBlur: handleBlur, onValueChange: ({ floatValue }) => {
                    if (!floatValue) {
                        setFieldValue(REQUESTED_AMOUNT, '');
                        return;
                    }
                    setFieldValue(REQUESTED_AMOUNT, floatValue);
                }, required: true, error: (_l = errors.cqs) === null || _l === void 0 ? void 0 : _l.requestedAmount, isTouched: (_m = touched.cqs) === null || _m === void 0 ? void 0 : _m.requestedAmount }), _jsxs(Grid, { container: true, wrap: "nowrap", spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(InputField, { type: "number", label: "TAN", name: "cqs.tan", onChange: handleChange, value: values.cqs.tan, onBlur: handleBlur, endAdornmentLabel: "%", required: true, error: (_o = errors.cqs) === null || _o === void 0 ? void 0 : _o.tan, isTouched: (_p = touched.cqs) === null || _p === void 0 ? void 0 : _p.tan }) }), _jsx(Grid, { item: true, children: _jsx(InputField, { type: "number", label: "Punti applicati", name: "cqs.points", onChange: handleChange, onBlur: handleBlur, value: values.cqs.points, endAdornmentLabel: "%", required: true, error: (_q = errors.cqs) === null || _q === void 0 ? void 0 : _q.points, isTouched: (_r = touched.cqs) === null || _r === void 0 ? void 0 : _r.points }) })] }), _jsx(SelectField, { label: "Durata (mesi)", name: "cqs.duration", onBlur: handleBlur, onChange: e => {
                    handleChange(e);
                    if (values.cqs.installment === '') {
                        return;
                    }
                    const duration = Number(e.target.value);
                    setFieldValue(INTEREST, values.cqs.installment * duration);
                }, options: ['24', '36', '48', '60', '72', '84', '96', '108', '120'], value: values.cqs.duration, required: true, error: (_s = errors.cqs) === null || _s === void 0 ? void 0 : _s.duration, isTouched: (_t = touched.cqs) === null || _t === void 0 ? void 0 : _t.duration }), _jsx(CurrencyField, { fieldLabel: "Rata", name: INSTALLMENT, value: values.cqs.installment, onBlur: handleBlur, onValueChange: value => {
                    if (!value.floatValue) {
                        setFieldValue(INSTALLMENT, '');
                        return;
                    }
                    setFieldValue(INSTALLMENT, value.floatValue);
                    if (values.cqs.duration === '') {
                        return;
                    }
                    const duration = Number(values.cqs.duration);
                    const interest = value.floatValue * duration;
                    setFieldValue(INTEREST, interest);
                    if (values.cqs.interestLeft === '') {
                        return;
                    }
                    const interestDifference = interest - values.cqs.interestLeft;
                    setFieldValue(INTEREST_DIFFERENCE, interestDifference);
                }, required: true, error: (_u = errors.cqs) === null || _u === void 0 ? void 0 : _u.installment, isTouched: (_v = touched.cqs) === null || _v === void 0 ? void 0 : _v.installment }), _jsx(CurrencyField, { fieldLabel: "Montante", allowNegative: true, name: INTEREST, value: values.cqs.interest, required: true, error: (_w = errors.cqs) === null || _w === void 0 ? void 0 : _w.interest, isTouched: (_x = touched.cqs) === null || _x === void 0 ? void 0 : _x.interest, disabled: true, onBlur: handleBlur, onValueChange: interestValue => {
                    setFieldValue(INTEREST, interestValue.floatValue);
                    if (!interestValue.floatValue) {
                        setFieldValue(INTEREST, '');
                        return;
                    }
                    if (values.cqs.interestLeft === '') {
                        return;
                    }
                    const interestDifference = interestValue.floatValue - values.cqs.interestLeft;
                    setFieldValue(INTEREST_DIFFERENCE, interestDifference);
                } }), values.cqs.type === 'Rinnovo' && (_jsx(CurrencyField, { fieldLabel: "Montante residuo", name: INTEREST_LEFT, value: values.cqs.interestLeft, required: true, error: (_y = errors.cqs) === null || _y === void 0 ? void 0 : _y.interestLeft, isTouched: (_z = touched.cqs) === null || _z === void 0 ? void 0 : _z.interestLeft, onBlur: handleBlur, onValueChange: interestValue => {
                    setFieldValue(INTEREST_LEFT, interestValue.floatValue);
                    if (!interestValue.floatValue) {
                        setFieldValue(INTEREST_LEFT, '');
                        return;
                    }
                    if (values.cqs.interest === '') {
                        return;
                    }
                    const interestDifference = values.cqs.interest - interestValue.floatValue;
                    setFieldValue(INTEREST_DIFFERENCE, interestDifference);
                } })), values.cqs.type === 'Rinnovo' && (_jsx(CurrencyField, { allowNegative: true, fieldLabel: "Delta montante", value: values.cqs.interestDifference, onBlur: handleBlur, onValueChange: ({ floatValue }) => setFieldValue(INTEREST_DIFFERENCE, floatValue), name: INTEREST_DIFFERENCE, required: true, error: (_0 = errors.cqs) === null || _0 === void 0 ? void 0 : _0.interestDifference, isTouched: (_1 = touched.cqs) === null || _1 === void 0 ? void 0 : _1.interestDifference, disabled: true }))] }));
};
